import React, { Suspense, useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Menu } from "antd";
import { Route, Switch, Link, useParams, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FormattedMessage } from "react-intl";
const { Header, Sider, Footer, Content } = Layout;

function SideMenu() {
  const location = useLocation();
  const [isActiveMenu, setActiveMenu] = useState(false);
  const { appointmentid } = useParams();
  const [serviceOrderType, setServiceOrderType] = useState();
  const { appointmentList } = useSelector((state) => state.AppointmentsSlice);
  const summaryURL = `/appointment/${appointmentid}/summary`;
  const equipmentURL = `/appointment/${appointmentid}/equipments`;
  const labourURL = `/appointment/${appointmentid}/labour`;
  const partsURL = `/appointment/${appointmentid}/parts`;
  const refrigerantURL = `/appointment/${appointmentid}/refrigerant`;
  const stageURL = `/appointment/${appointmentid}/stage`;
  const tasksURL = `/appointment/${appointmentid}/tasks`;
  const othercostURL = `/appointment/${appointmentid}/othercost`;
  const workdoneURL = `/appointment/${appointmentid}/workdone`;
  const attachmentsURL = `/appointment/${appointmentid}/attachments`;
  const additionalworkURL = `/appointment/${appointmentid}/additionalwork`;
  const preventative_maintenanceURL = `/appointment/${appointmentid}/preventative_maintenance`;
  const preapproved_quoteURL = `/appointment/${appointmentid}/preapproved_quote`;
  const servicecalltimeURL = `/appointment/${appointmentid}/servicecalltime`;
  const orderURL = `/appointment/${appointmentid}/order`;
  const { pathname } = location;
  const serviceOrdersOfPM = ["PM", "PMC", "PMCB", "PMCT"];
  const serviceOrdersOfOH = ["OHVT", "OHCB"];

  useEffect(() => {
    if (appointmentList.length > 0) {
      let item = appointmentList.filter((item) => item.noteID == appointmentid);
      if (item.length > 0) {
        setServiceOrderType(item[0]?.serviceOrderType.trim());
      }
    }
  }, [appointmentid, appointmentList]);
  return (
    <>
      {appointmentid && pathname.indexOf("visithistory") < 0 && (
        <Sider collapsedWidth="60" width={120} className="test">
          <div className="menu">
            <div className="h-100">
              <PerfectScrollbar>
                <Menu theme="light" mode="inline">
                  {/* {process.env.REACT_APP_DASHBOARD_TOGGLE == 1 && ( */}
                  <Menu.Item
                    key="0"
                    className={
                      pathname.indexOf("/summary") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="iconsminds-file-clipboard-file---text"></i>
                    <span>
                      <FormattedMessage id="Summary.Summary" />
                    </span>
                    <Link to={summaryURL} />
                  </Menu.Item>
                  {serviceOrdersOfOH.includes(serviceOrderType) ? (
                    <Menu.Item disabled>
                      <i className="iconsminds-wrench"></i>{" "}
                      <span>
                        <FormattedMessage id="Tasks.SideMenuEquipment" />
                      </span>
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      key="2"
                      className={
                        pathname.indexOf("/equipments") > -1
                          ? "ant-menu-item-selected"
                          : ""
                      }
                    >
                      <i className="iconsminds-wrench"></i>{" "}
                      <span>
                        <FormattedMessage id="Tasks.SideMenuEquipment" />
                      </span>
                      <Link to={equipmentURL} />
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="3"
                    className={
                      pathname.indexOf("/labour") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="iconsminds-engineering"></i>{" "}
                    <span>
                      <FormattedMessage id="Labour.Labour" />
                    </span>
                    <Link to={labourURL} />
                  </Menu.Item>
                  <Menu.Item
                    key="4"
                    className={
                      pathname.indexOf("/parts") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="iconsminds-inbox"></i>{" "}
                    <span>
                      <FormattedMessage id="Parts.Parts" />
                    </span>
                    <Link to={partsURL} />
                  </Menu.Item>
                  {/* <Menu.Item
                    key="5"
                    className={
                      pathname.indexOf("/stage") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="iconsminds-box-with-folders"></i>{" "}
                    <span>Stage</span>
                    <Link to={stageURL} />
                  </Menu.Item> */}

                  {/* {serviceOrdersOfPM.includes(serviceOrderType) ? (
                    <Menu.Item
                      key="6"
                      className={
                        pathname.indexOf("/tasks") > -1
                          ? "ant-menu-item-selected"
                          : ""
                      }
                    >
                      <i className="iconsminds-check"></i>{" "}
                      <span>
                        {" "}
                        <FormattedMessage id="Tasks.Tasks" />
                      </span>
                      <Link to={tasksURL} />
                    </Menu.Item>
                  ) : (
                    <Menu.Item disabled>
                      <i className="iconsminds-check"></i>{" "}
                      <span>
                        {" "}
                        <FormattedMessage id="Tasks.Tasks" />
                      </span>
                    </Menu.Item>
                  )} */}

                  {/* <Menu.Item
                    key="7"
                    className={
                      pathname.indexOf("/othercost") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="iconsminds-money-bag"></i>{" "}
                    <span>Other Cost</span>
                    <Link to={othercostURL} />
                  </Menu.Item> */}
                  <Menu.Item
                    key="8"
                    className={
                      pathname.indexOf("/workdone") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="simple-icon-briefcase"></i>{" "}
                    <span>
                      {" "}
                      <FormattedMessage id="WorkDone.Workdone" />
                    </span>
                    <Link to={workdoneURL} />
                  </Menu.Item>
                  <Menu.Item
                    style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }}
                    key="9"
                    className={
                      pathname.indexOf("/additionalwork") > -1
                        ? "ant-menu-item-selected"
                        : "last-new-menu"
                    }
                  >
                    <i className="iconsminds-add-file"></i>{" "}
                    <span>
                      <FormattedMessage id="AdditionalWork.AdditionalWorkheading" />
                    </span>
                    <Link to={additionalworkURL} />
                  </Menu.Item>
                  {/* <Menu.Item
                    key="10"
                    className={
                      pathname.indexOf("/preventative_maintenance") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="iconsminds-notepad"></i> <span>PM</span>
                    <Link to={preventative_maintenanceURL} />
                  </Menu.Item>
                  <Menu.Item
                    key="11"
                    className={
                      pathname.indexOf("/preapproved_quote") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="iconsminds-billing"></i>{" "}
                    <span>Pre-app. Quote</span>
                    <Link to={preapproved_quoteURL} />
                  </Menu.Item>
                  <Menu.Item
                    key="12"
                    className={
                      pathname.indexOf("/servicecalltime") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="iconsminds-time-backup"></i>{" "}
                    <span>Service call Time</span>
                    <Link to={servicecalltimeURL} />
                  </Menu.Item>
                  <Menu.Item
                    key="13"
                    className={
                      pathname.indexOf("/order") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="iconsminds-basket-quantity"></i>{" "}
                    <span>Order</span>
                    <Link to={orderURL} />
                  </Menu.Item> */}
                  <Menu.Item
                    key="14"
                    className={
                      pathname.indexOf("/attachments") > -1
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    <i className="simple-icon-folder-alt"></i>{" "}
                    <span>
                      {" "}
                      <FormattedMessage id="Attachments.Attachments" />
                    </span>
                    <Link to={attachmentsURL} />
                  </Menu.Item>

                  {/* )} */}
                </Menu>
              </PerfectScrollbar>
            </div>
          </div>
        </Sider>
      )}
    </>
  );
}

export default SideMenu;
