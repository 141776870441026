import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function GlobalSpinner(props) {
  const { loader } = useSelector((state) => state.GlobalSpinner);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  console.log("window.innerHeight", window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerHeight;
      if (newHeight < viewportHeight - 100) {
        // Assume keyboard is open if height reduces significantly
        setIsKeyboardOpen(true);
      } else {
        setIsKeyboardOpen(false);
      }
      setViewportHeight(newHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [viewportHeight]);

  return (
    <>
      <div
        className="height100vh"
        style={{ height: isKeyboardOpen ? "calc(100vh - 50px)" : "100vh" }}
      >
        <Spin spinning={loader} size="large">
          {props.appContainer}
        </Spin>
      </div>
    </>
  );
}

export default GlobalSpinner;
